export const applyCurrencyFormatters = (columns) => {
  return columns.map((c) => {
    if (c.format === 'currency') {
      return {
        ...c,
        format: (row) => formatCurrency(c.selector(row)),
      }
    }
    return c;
  })
};

export const formatCurrency = (value) => {
  if (value === undefined || value < 0 && value > -0.001) { // avoid rendering -0.00
    console.log(value);
    value = 0;
  }
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
  });
}
