import {Card, CardContent, Link, Typography} from "@mui/material";
import React from "react";

const SingleReview = ({ id, reviewLink, reviewLinkText, review, reviewAuthor }) => {
    return <Card sx={{ minWidth: 275 }}>
        <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                {review}
            </Typography>
            <Typography variant="h5" component="div">
                {reviewAuthor}
            </Typography>

            <Link href={reviewLink} target="_blank"
                  rel="noopener noreferrer">{reviewLinkText}</Link>
        </CardContent>
    </Card>
}

export default SingleReview;