
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import React, {useEffect, useState} from "react";
import {Grid, styled, Typography} from "@mui/material";
import { StyledContainer } from "../../../../new_components/layouts/MainLayout";
import GetInTouch from "../get-in-touch";
import InfiniteCarousel from 'react-leaf-carousel';
import SingleReview from "./SingleReview";
import {testimonialsApi} from "../../../../api/testimonialsApi";

const StyledHeading = styled(Typography)(({ theme }) => ({
    maxWidth: theme.spacing(48),
    fontSize: "clamp(2.5rem, -0.9091rem + 14.5455vw, 3rem)",
}));

const Reviews = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        testimonialsApi.getTestimonials().then(data => {
            setTestimonials(data)
        })
    }, []);

    return (
        <StyledContainer container>
            <Grid item width="100%">
                <Grid container flexDirection="column" alignItems="center" gap={0.5} sx={{ marginBottom: 5 }}>
                    <StyledHeading variant="h2">
                        <UnderlinedHeaderTextWord>Testimonials</UnderlinedHeaderTextWord>
                    </StyledHeading>
                </Grid>

                {testimonials && testimonials.length > 0 && <ReviewsCarousel testimonials={testimonials}/>}
            </Grid>
            <GetInTouch />
        </StyledContainer>
    );
}

const ReviewsCarousel = ({ testimonials }) => {
    return <InfiniteCarousel
        breakpoints={[
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
        ]}
        showSides
        sidesOpacity={.8}
        sideSize={.5}
        slidesToScroll={2}
        slidesToShow={3}
        arrows
        scrollOnDevice
        autoCycle
        cycleInterval={3500}
        pauseOnHover
    >
        {testimonials.map(data => {
            return <SingleReview
                key={data.id}
                reviewLink={data.reviewLink}
                reviewLinkText={data.reviewLinkText}
                review={data.review}
                reviewAuthor={data.reviewAuthor}
            />
        })}
    </InfiniteCarousel>
}

export default Reviews;