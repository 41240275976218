import HttpClient from "./api";

class TestimonialsApi extends HttpClient {
    public constructor() {
        super();
    }

    getTestimonials() {
        return this.publicDoGet('/api/v3/testimonials');
    }
}

export const testimonialsApi = new TestimonialsApi();